export const environment = {
  production: false,

  //****************************** Cognito ********************************/
  clientID: '1f5h5b75ubf28buhbsptds1hjn',
  clientSecret: '1oon1gb9mdj41p84gspak6sih350tc5takh6copl12espkt0sn81',
  cognitoBaseURL: 'https://jmvl.auth.eu-west-2.amazoncognito.com/',
  redirectURI: 'https://www.jmvl.co.uk/admin',

  callBackAPIUrl: "https://ne2uqqd39a.execute-api.eu-west-2.amazonaws.com/prod/callback",
  contactUsAPIUrl: "https://ne2uqqd39a.execute-api.eu-west-2.amazonaws.com/prod/contactus",
  getProductsAPIUrl: "https://ne2uqqd39a.execute-api.eu-west-2.amazonaws.com/prod/products",
  getTeamMembersAPIUrl: "https://ne2uqqd39a.execute-api.eu-west-2.amazonaws.com/prod/team",

  getPostcodesURL: 'https://ddwy4l4a7e.execute-api.eu-west-2.amazonaws.com/prod/stats',
  getUsersDetailsAPI: 'https://ddwy4l4a7e.execute-api.eu-west-2.amazonaws.com/prod/user-metrics'
};
