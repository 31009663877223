import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(public http: HttpClient) { }

  callbackRequest(params: any) {
    return this.http.post(`${environment.callBackAPIUrl}`, params, this.httpOptions);
  }

  sendMessage(params: any) {
    return this.http.post(`${environment.contactUsAPIUrl}`, params, this.httpOptions);
  }

  getProducts() {
    return this.http.get(`${environment.getProductsAPIUrl}`, this.httpOptions);
  }

  getTeamMembers() {
    return this.http.get(`${environment.getTeamMembersAPIUrl}`, this.httpOptions);
  }

  getPostcodes() {
    return this.http.get(`${environment.getPostcodesURL}`, this.httpOptions);
  }

  getUserMetrics() {
    return this.http.get(`${environment.getUsersDetailsAPI}`, this.httpOptions);
  }

  postcodeLookup(postcode: any) {
    var url = 'https://api.postcodes.io/postcodes/' + postcode;
    return this.http.get(url,this.httpOptions);
  }
}
