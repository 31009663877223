import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';
import { CognitoService } from '../../services/cognito.service';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';

declare var $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  callbackForm: FormGroup;
  submitted = false;
  refrence: any;
  setNavBack = false;
  lastScrollTop = 0;
  url: string;
  isUserExist = false;

  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private apiService: ApiService,
    private cognitoService: CognitoService
  ) { }

  ngOnInit() {
    this.callbackForm = this.formbuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      terms: new FormControl('', Validators.requiredTrue)
    });
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.url = e.url;
      }
    });

    if (localStorage.getItem('tokens') === null) {
      this.isUserExist = false;
      console.log("User does not exist");
    } else if (localStorage.getItem('user') !== null){
      this.isUserExist = true;      
      console.log("User exist");
    } else {
      this.isUserExist = false;
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    // this.setNavBack = true;
    var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
   if (st > this.lastScrollTop){
      // downscroll code
   } else {
      // upscroll code
   }
   this.lastScrollTop = st <= 0 ? 0 : st;

  }

  get f() { return this.callbackForm.controls; }

  openVerticallyCentered(content) {
    this.refrence = this.modalService.open(content, { centered: true });
  }

  openVerticallyCenteredThanksPopup(thankscontent) {
    this.modalService.open(thankscontent, { centered: true, size: 'sm' });
  }

  openPrivayPolicy(contentPrivacyPolicy) {
    this.modalService.open(contentPrivacyPolicy, { centered: true, size: 'lg', scrollable: true });
  }

  callbackFormData(thankscontent) {
  	this.submitted = true;
  	if (this.callbackForm.invalid) {
  		return;
    }
    
    // Api service
    console.log('Call Back Form: ', this.callbackForm.value);
    this.apiService.callbackRequest(this.callbackForm.value).subscribe((res: any) => {
      console.log('Response from Callback: ', res);
      if (res.statusCode === 200) {
        this.openVerticallyCenteredThanksPopup(thankscontent);
        this.callbackForm.reset();
      }
      this.refrence.dismiss();
      this.submitted = false;
    });
  }

  navigateToUrl() {
    $(".navbar-toggler").click();
  }

  user() {
    console.log("User Function Called");
    if(!this.isUserExist) {
      console.log("User does not exist - login called");
      this.isUserExist = true;
      this.login();
    } else {
      console.log("User exist - logout called");
      this.isUserExist = false;
      this.logout();
    }
  }

  login() {
    this.cognitoService.login();    
  }

  logout() {    
    Swal.fire({  
      title: 'Are you sure want to Logout?',        
      icon: 'warning',  
      showCancelButton: true,  
      confirmButtonText: 'Yes, Logout!',  
      cancelButtonText: 'No, Keep me LoggedIn'  
    }).then((result) => {
      if (result.value) {
        this.cognitoService.logout();
        location.reload();
        this.router.navigate(['/']); 
      }
    });    
  }
}
